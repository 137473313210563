import React, { useEffect, useState } from 'react';
import { Grid, Rating } from '@mui/material';
import Carousel, { ArrowProps } from 'react-multi-carousel';
import Flag from 'react-world-flags';
import styles from './testimonials.module.scss';
import 'react-multi-carousel/lib/styles.css';
import { EmptyAvatar } from '../../../../assets/images';
import { QCNewLogo } from '../../../../assets/svg';
import { UserService } from '../../../../common/services/user/user.service';

const Avatar = ({ imageUrl }) => {
  return (
    <div className={styles.imageWrapper}>
      <img src={imageUrl?.length ? imageUrl : EmptyAvatar} alt="avatar" />
    </div>
  );
};

const TestimonialCard = ({ item, index }) => {
  return (
    <div className={styles.card} key={index}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <p className={styles.testimonialText}>{item?.testimonial}</p>
          <hr />
          <div className={styles.textWrapper}>
            <div>
              <div className={styles.cardUserName}>
                {item?.user?.publicDisplayName}
              </div>

              {typeof item?.countryCode === 'object' && (
                <div className={styles.flagWrapper}>
                  <span className={styles.countryName}>
                    {item?.countryCode?.[0]}
                  </span>
                  <Flag
                    code={item?.countryCode?.[0]?.toLowerCase()}
                    height="12"
                  />
                </div>
              )}
            </div>
            <div>
              <div className={styles.ratingTitle}>User rating</div>
              <div>
                <Rating name="read-only" value={item.rating} readOnly max={4} />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Avatar imageUrl={QCNewLogo} />
        </Grid>
      </Grid>
    </div>
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <></>
    // <button
    //   type="button"
    //   onClick={onClick}
    //   aria-label="Go to next slide"
    //   className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--right ${styles.sliderArrowRight}`}
    // />
  );
};

const CustomLeftArrow = ({ onClick }) => {
  return (
    <></>
    // <button
    //   type="button"
    //   onClick={onClick}
    //   aria-label="Go to next slide"
    //   className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--left ${styles.sliderArrowLeft}`}
    // />
  );
};
const userService = new UserService();

const Testimonials = () => {
  const [testimonialData, setTestimonialData] = useState([]);
  const [called, setCalled] = useState(false);
  useEffect(() => {
    const getAllFAQs = async () => {
      setCalled(true); // Ensure the function doesn't run again
      try {
        const result = await userService.getTestimonials();
        setTestimonialData(result); // Properly set the FAQ data
      } catch (err) {
        console.error('Failed to fetch FAQs:', err);
      }
    };

    if (!called) {
      getAllFAQs();
    }
  }, []);

  return (
    <div className={styles.mainContainer} id="testimonials">
      <h2>Testimonials</h2>
      <Carousel
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 1,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
          },
        }}
        showDots
        centerMode={false}
        infinite
        additionalTransfrom={0}
        // arrows
        // autoPlay
        autoPlaySpeed={2000}
        shouldResetAutoplay
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        slidesToSlide={1}
        swipeable
        draggable={false}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {testimonialData?.map((item, index) => (
          <TestimonialCard item={item} index={index} />
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
